.l-main {
  &.is-exiting {
    //animation-name: fadeIn;
  }

  padding-bottom: $spacing-l;

  @media (min-width: $breakpoint-20) {
    padding-bottom: 0;
  }
}