$block: 'c-tabs';
.#{$block} {
  &__list {
    border-top: 1px solid $color-neutral-20;
    border-bottom: 1px solid $color-neutral-20;
    display: flex;
    text-align: center;
    justify-content: center;
    list-style: none;
    margin: 0 auto 0 auto;
    width: 100%;
    max-width: $spacing*60;
  }

  &__item {
    flex: 1;
    color: $color-neutral-70;
    position: relative;

    &:not(:first-child) {
      border-left: 1px solid $color-neutral-20;
    }
  }

  &__link {
    color: $color-primary-50;
    display: block;
    font-size: $font-size-s;
    line-height: 1;
    min-height: 40px;
    padding: $spacing*2;

    &:hover {
      background: $color-white;
    }

    .#{$block}__item--selected & {
      font-weight: 600;
      background: $color-white;
      color: $color-black;
    }
  }

  &__content {
    @include grid-container;
    padding: $spacing*3 0;
  }
}