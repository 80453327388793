$block: 'c-card';
.#{$block} {
  align-items: flex-start;
  background: $color-white;
  border-bottom: $default-border;
  display: flex;
  min-height: $spacing*7;
  padding: $spacing-s $spacing-m 0 $spacing-m;
  position: relative;
  width: 100%;

  &--block {
    display: block;
  }

  &--hidden {
    display: none;
  }

  &--no-border {
    border-bottom: none;
  }

  &--indent {
    padding-left: $spacing-l;
  }
  
  &--selected {
    background: $color-neutral-20;
  }

  &--hovered {
    background: $color-neutral-20;
  }

  &__image {
    margin-bottom: $spacing-s !important;
  }

  &__content {
    flex: 1;

    .c-veld-detail & {
      padding-right: $spacing-xs;
    }

    a {
      .c-sidebar & {
        display: block;
      }
    }
  }

  &__notifications {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    top: calc(50% - 8px);
  }

  &__header {
    background-size: cover;
    display: flex;
    align-items: flex-end;
    padding: $spacing-s $spacing-m;
    background-position: center;
    min-height: $spacing*25;
    margin: -16px -24px 24px -24px;
  }
  
  &__options {
    margin-bottom: $spacing-s;
    display: flex;
    list-style: none;
  }

  &__options-item {

    &:not(:first-child) {
      margin-left: $spacing-m;
    }
  }

  .c-label {
    margin-top: $spacing-xxs;
    margin-right: $spacing-xs;
  }
}

.c-dl + .#{$block}__options {
 margin-top: -$spacing-xs;
}

//svg.c-card__arrow {
//  fill: $color-neutral-30;
//  position: absolute;
//  top: calc(50% - #{$spacing-m/2});
//  right: 0;
//  transform: translate3d(0, 0, 0);
//  transition: $timing-20;
//
//  .c-card__content:hover & {
//    fill: $color-primary-50;
//    transform: translate3d(2px, 0, 0);
//  }
//}
