/*------------------------------------*\
  #BASE-FORMS
\*------------------------------------*/

form {  }

fieldset {
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  padding: 0;
}

input[type=text],
input[type=number],
input[type=password],
input[type=search],
input[type=email],
input[type=tel],
input[type=url],
input[type=date],
textarea,
select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: $color-white;
  border-radius: $border-radius-20;
  border: 1px solid $color-neutral-30;
  color: $color-black;
  display: block;
  font-family: $font-family-heading;
  font-size: $font-size-m;
  line-height: 1.5em;
  font-weight: 600;
  margin: 0;
  outline: none;
  padding: 0.55rem 0.75rem 0.55rem 0.75rem;
  vertical-align: baseline;
  width: 100%;
  transition: $timing-10;

  &:focus {
    border: 1px solid $color-neutral-40;
    box-shadow: $shadow-10;
    outline: 0;
  }

  &:disabled {
    background: $color-neutral-10;
    color: $color-neutral-30;
  }
}

textarea {
  display: block;
  overflow: auto;
  resize: vertical;
  vertical-align: top;
}

select {
  height: 2.75rem;

  &[multiple] {
    height: auto;
  }
}
