$block: 'c-popover';
.#{$block} {
  display: inline-block;
  position: relative;

  &__content {
    background: $color-white;
    border-bottom: $default-border;
    left: -$spacing-s;
    min-width: 240px;
    padding: $spacing-s $spacing-s 0 $spacing-s;
    position: absolute;
    pointer-events: none;
    opacity: 0;
    top: -$spacing-s;
    z-index: $z-index-30;
    transition: $timing-10 $bezier;
    transform: translate3d(0, -90% , 0);

    .#{$block}--open & {
      opacity: 1;
      transform: translate3d(0, -100% , 0);
      pointer-events: auto;
    }

    &:before {
      content: '';
      position: absolute;
      left: $spacing-s;
      bottom: -$spacing-xs;
      width: 0;
      height: 0;
      border-left: $spacing-xs solid transparent;
      border-right: $spacing-xs solid transparent;
      border-top: $spacing-xs solid $color-white;
    }

  }

  &__list {
    list-style: none;
    margin-bottom: $spacing-s;
  }
}