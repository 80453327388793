$block: 'c-buttons-wrapper';
.#{$block} {
  align-items: center;
  margin-bottom: $spacing-m;

  @media (min-width: $breakpoint-10) {
    display: flex;
  }

  &--small-spacing {
    margin-bottom: $spacing-xxs;
  }

  &--spacing-s {
    margin-bottom: $spacing-s;
  }

  .c-button {
    display: block;
    margin-bottom: $spacing;

    @media (min-width: $breakpoint-10) {
      display: inline-block;
    }
    
    &:not(:first-child) {
      margin-top: $spacing;
      
      @media (min-width: $breakpoint-10) {
        margin-top: 0;
        margin-left: $spacing*2; 
      }
    }
  }

  &__copy {
    display: inline-block;
    vertical-align: middle;
    margin: $spacing $spacing*2 $spacing*2 $spacing;
  }

  &__paragraph {
    @media (min-width: $breakpoint-10) {
        margin-bottom: $spacing;
    }
  }
}
