$block: 'c-content';
.#{$block} {
  padding-bottom: $spacing-s;
  font-size: $font-size-s;
  opacity: 0;

  .is-animating & {
    animation: fadeInUp $timing-30 forwards;
  }

  // animatie delays
  @for $i from 1 through 20 {
    &:nth-child(#{$i}){
      animation-delay:(#{$i*0.2s});
    }
  }

  @media (min-width: $breakpoint-10) {
    font-size: $font-size-m;
  }

  figure {
    display: inline-block;
    margin: $spacing-m 0 $spacing-m -48px;
    width: calc(100% + #{$spacing-xxl});

    img {
      display: block;
      width: 100%;
      max-width: 100%;
      margin: 0;
    }

    figcaption {
      margin-top: 10px;
      font-size: 14px;
      color: $color-neutral-40;
    }
  }

  h2, 
  h3,
  h4 {
    margin-top: $spacing*2;

    @media (min-width: $breakpoint-20) {
      margin-top: $spacing*4;
    }
  }

  h2 {
    font-size: $font-size-l;

    @media (min-width: $breakpoint-20) {
      font-size: $font-size-xl;
    }

    &:first-of-type {
      margin-top: 0;
    }
  }

  ul,
  ol {
    list-style: none;
    margin-bottom: $spacing*2;
    margin-top: $spacing*2;
    padding: 0;

    li {
      font-style: normal;
      letter-spacing: .002em;
      line-height: 1.7;
      padding-left: $spacing-xxl;
      margin: 0 0 $spacing 0;
      position: relative;

      @media (min-width: $breakpoint-10) {
        margin: 0 0 $spacing $spacing-m;
      }
    }
  }

  ol {
    counter-reset: item;
    li {

    }
    li:before {
      content: counters(item, ".") ". "; 
      counter-increment: item;
      height: $spacing-l;
      width: $spacing-l;
      top: calc( 50% - #{calc($spacing-l/2)});
    }
  }

  ul + p { margin-top: $spacing*3; }
  ol + p { margin-top: $spacing*3; }

  figure + p {
    margin-top: 0;
  }

  figure + h2,
  figure + h3,
  figure + h4,
  figure + p {
    margin-top: 0;
  }
}