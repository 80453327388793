/*------------------------------------*\
  #BASE-TYPE
\*------------------------------------*/

/*
 * Headings
 */
h1, h2, legend, h3, h4, h5, h6 {
  font-family: $font-family-heading;
  font-weight: 600;
  line-height: 1.35;
  margin: 0 0 $spacing 0;
  color: $color-black;
}

legend {
  font-weight: normal;

  &.has-indent {
    padding: $spacing 0 1.25rem 0;
    width: 101%;
    background: $color-white;
    margin: -2px -1px 0 -1px;
  }

  &.has-border {
    border-bottom: 1px solid $color-neutral-30;
    display: block;
    width: 100%;
    padding-bottom: calc($spacing/2);
  }
}

/*
 * Paragraphs
 */
p {
  margin: 0 0 $spacing*3 0;
  line-height: 1.7;
}

/*
 * Links
 */
a {
  color: $color-primary-50;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: $color-primary-90;
  }

  svg {
    fill: $color-primary-50;
  }
}



/*
 * Other
 */
small {
  color: $color-neutral-50;
  font-size: $font-size-s;
}

em { font-style: italic; }

strong { 
  font-weight: 600;
}

code, pre { font-family: 'courier new', monospace; }

pre { overflow-x: auto; }

abbr { border-bottom: 1px dotted $color-neutral-50; }

del { text-decoration: line-through; }

mark { background-color: lighten(yellow, 35%); }

hr {
  background-color: transparent;
  border: solid $color-neutral-30;
  border-width: 1px 0 0;
  clear: both;
  margin: 2.5rem $spacing;
}

ul,
ol {
  margin: 0 0 $spacing*3 0;
  padding: 0;
}


dl,
.c-dl {
  @include grid-container;
  font-size: $font-size-s;
  margin: calc($spacing/2) 0 $spacing*2 0;
  width: 100%;

  &--soft {
    color: $color-neutral-40;
  }

  dt,
  dd {
    display: block;

    @media (min-width: $breakpoint-5) {
      float: left;
    }
  }

  dt {
    font-weight: 600;

    @media (min-width: $breakpoint-5) {
      clear: left;
      width: 130px;
    }
  }

  dd {
    margin: 0 0 $spacing-xs 0;
  }

  &--light dt {
    font-weight: normal;
  }

  &--font-size-s {
    font-size: $font-size-xs;

    dd {
      margin-bottom: calc($spacing-xxs/2);
    }
  }
}

address {
  margin-bottom: $spacing-s;
}
