/*------------------------------------*\
  #OBJECTS-MEDIA
\*------------------------------------*/

.c-media {
  $size-xs: $spacing*2;
  $size-s: $spacing*3;
  $size-m: $spacing*6;

  background-repeat: no-repeat;
  background-size: 100%;
  margin: 0 0 $spacing-m 0;

  .c-table & {
    margin: 0;
    position: relative;
    top: 5px;
    margin-right: $spacing-xxs;
    z-index: $z-index-10;
  }

  .c-card & {
    margin: 0 $spacing-s 0 0
  }

  &--logo {
    align-items: center;
    display: flex;
    display: inline-block;
    justify-content: center;
    margin-left: $spacing-s;
    margin-right: $spacing-s;
    max-height: $spacing*12;
    object-fit: cover;

    img {
      max-height: $spacing*8;
    }
  }

  &--no-spacing {
    margin-bottom: 0;
  }

  &--image {
    background-position: center;
    background-size: contain;
    display: inline-block;
    width: 100%;

    &--auto {
      img {
        width: auto;
      }
    }

    &--half {
      width: 50%;
    }

    img {
      display: block;
      max-width: 100%;
      margin: auto;
    }
  }

  &--size-xs {
    height: $size-xs;
    width: $size-xs;
  }

  &--size-s {
    height: $size-s;
    width: $size-s;
  }

  &--size-m {
    height: $size-s;
    width: $size-s;

    @media (min-width: $breakpoint-30) {
      height: $size-m;
      width: $size-m;
    }
  }

  &--pie {
    margin: 0 $spacing-xxs 0 0 !important;
  }

  &--spacing {
    margin: 0 0 $spacing-m 0 !important;
  }

  &--padding {
    padding: 10px !important;
  }
}


.c-media--video {
  height: 0;
  padding-bottom: 56%;
  position: relative;
  width: 100%;

  iframe,
  object {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
