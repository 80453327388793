$block: 'c-page-header';
.#{$block} {
  position: relative;
  display: flex;
  margin-bottom: $spacing-m;
  justify-content: space-between;
  align-items: center;

  &__title {
    vertical-align: baseline;
    display: inline-block;
    margin-bottom: 0;
    flex: 1;
    margin-right: $spacing-s;
  }

  &__title-wrapper {
    display: flex;
  }

  &__link {

    &:not(:first-child) {
      margin-left: $spacing-m;
    }
  }
}