/*------------------------------------*\
  #BASE-QUOTE
\*------------------------------------*/

blockquote,
.c-quote {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: $font-size-s;
  font-style: italic;
  font-weight: 600;

  margin: 0 0 $spacing-m 0;
  padding: 0;
  position: relative;
  quotes: none;
  backface-visibility: hidden;

  @media (min-width: $breakpoint-10) {
    opacity: 0;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-around;
    font-size: $font-size-m;
  }

  &--spacing {
    margin-bottom: $spacing-xl;
  }

  .is-animating & {
    @media (min-width: $breakpoint-10) {
      animation: fadeInUp $timing-30 forwards;
    }
  }

  // animatie delays
  @for $i from 1 through 20 {
    &:nth-child(#{$i}){
      animation-delay:(#{$i*0.2s});
    }
  }

  small {
    color: $color-neutral-40;
    font-weight: 400;

    [class*="u-background-"] & {
      color: $color-neutral-10;
    }
  }

  p {
    margin-bottom: $spacing-s;

    @media (min-width: $breakpoint-10) {
      margin-bottom: $spacing-m;
    }
  }
  
  .c-media {
    display: flex;
    margin-top: $spacing-s;
    margin-bottom: $spacing-s;

    @media (min-width: $breakpoint-10) {
      margin-right: $spacing-m;
    }

  }

  div {
    flex: 1;
  }
}

cite {
  color: $color-neutral-50;
  display: block;
  font-size: $font-size-s;
  font-style: normal;
  font-weight: bold;
  margin-top: 5px;

  [class*="u-background-"] & {
    color: $color-neutral-10;
  }
}

.c-quote + .c-content {
  margin-top: $spacing-m;
}