$block: 'c-link';
.#{$block} {
  position: relative;
  transition: $timing-10;

  svg {
    fill: $color-primary-50;
  }

  &:hover {

  }

  /*------------------------------------*\
    #Colors
  \*------------------------------------*/

  &--neutral {
    color: $color-neutral-40;

    svg {
      fill: $color-neutral-40;
    }

    &:hover {
      color: $color-primary-50;

      svg {
        fill: $color-primary-50;
      }
    }
  }

  &--disabled {
    cursor: not-allowed;
    color: $color-neutral-30;

    &:hover {
      color: $color-neutral-30;

      svg {
        fill: $color-neutral-30;
      }
    }

    svg {
      fill: $color-neutral-30;
    }
  }

  &--route {
    display: block;
  }

  &--close  {
    display: block; // DO NOT REMOVE
    text-align: right;
    padding: $spacing-xs $spacing-s;
  }
}

