$block: 'c-form-select';
.#{$block} {
  margin-bottom: $spacing*3;
  position: relative;
  border: 1px solid $color-neutral-30;
  border-radius: $border-radius-20;
  position: relative;

  &:after {
    $dimension: 4px;

    border-left: $dimension solid transparent;
    border-right: $dimension solid transparent;
    border-top: $dimension solid $color-white;
    content: '';
    height: 0;
    position: absolute;
    pointer-events: none;
    right: -4px;
    top: calc( 50% - 2px);
    width: 0;
    z-index: $z-index-10;
  }

  &--transparent {
    border: 0;

    select {
      background: none;
      background-color: $color-primary-50;
      color: $color-white;

      &:focus {
        box-shadow: none;
      }
    }
  }

  select {
    border: 0;
  }
  max-width: 100px;
  @media (min-width: $breakpoint-5) {
    max-width: 200px;
  }
}