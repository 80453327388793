@mixin layout ($var) {
  $padding-x: map-get($var, padding-x);
  $padding-y: map-get($var, padding-y);
  $padding-outer-x: map-get($var, padding-outer-x);
  $padding-outer-y: map-get($var, padding-outer-y);

  .l-element {
    padding: calc($padding-y / 2) calc($padding-x / 2);
    min-height: 1px;
    width: 100%;
  }
}

$element-spacing: (
  padding-x: $spacing*4,
  padding-y: $spacing*4,
  padding-outer-x: $spacing*2,
  padding-outer-y: $spacing*8
);

@include layout($element-spacing);