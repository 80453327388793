$block: 'c-paragraph';
.#{$block} {

  /*------------------------------------*\
  #Colors
  \*------------------------------------*/

  &--center {
    text-align: center;
  }

  &--font-size-xs {
    font-size: $font-size-xs;
  }

  &--font-size-s {
    font-size: $font-size-s;
  }

  &--font-size-m {
    font-size: $font-size-s;

    @media (min-width: $breakpoint-20) {
      font-size: $font-size-m;
    }
  }

  &--font-size-l {
    line-height: 1.6;
    font-size: $font-size-m;

    @media (min-width: $breakpoint-20) {
      font-size: $font-size-l;
    }
  }

  /*------------------------------------*\
  #Margins
  \*------------------------------------*/


  &--spacing-xs {
    margin-bottom: $spacing-xs;
  }

  &--spacing-s {
    margin-bottom: $spacing-s;
  }

  &--spacing-m {

    @media (min-width: $breakpoint-10) {
      margin-bottom: $spacing*6;
    }
  }

  &--spacing-l {

    @media (min-width: $breakpoint-10) {
      margin-bottom: $spacing*8;
    }
  }

  &--no-spacing {
    margin-bottom: 0;
  }

  /*------------------------------------*\
  #Weights
  \*------------------------------------*/

  &--font-weight-400 {
    font-weight: 400;
  }

  &--font-weight-500 {
    font-weight: 500;
  }

  &--font-weight-600 {
    font-weight: 600;
  }


  /*------------------------------------*\
  #Colors
  \*------------------------------------*/

  &--neutral {
    color: $color-neutral-40;
  }

  &--hidden {
    display: none;
  }
}
