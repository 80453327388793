.c-panel {
  @include grid-container;
  background: $color-white;
  position: relative;
  z-index: $z-index-20;

  @media (min-width: $breakpoint-30) {
    margin-bottom: $spacing-m;
    box-shadow: 0 10px 20px rgba($color-black, 0.15);
  }

  &--neutral {
    background: $color-neutral-10;
  }

  &--negative {

    &:first-of-type {

      @media (min-width: $breakpoint-30) {
        margin-top: calc(-100%/3);
      }
    }

    @media (min-width: $breakpoint-30) {
      margin-top: calc(-100%/3);
    }
  }

  &__content {
    padding: $spacing-s $spacing-s 0 $spacing-s;

    @media (min-width: $breakpoint-30) {
      padding: $spacing-l $spacing-l $spacing-xs $spacing-l;
    }
  }

  &__button {
    display: block;
  }
}