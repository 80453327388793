.c-button,
button.c-button,
button,
.gform_button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: $color-success-50;
  border-radius: $border-radius-10;
  box-shadow: 0 2px $color-success-70;
  border: 0;
  color: $color-white;
  cursor: pointer;
  display: inline-block;
  font-size: $font-size-s;
  min-width: $spacing * 10;
  font-weight: bold;
  line-height: 1;
  padding: $spacing * 1.5 $spacing * 2;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-shadow: $shadow-10;
  vertical-align: bottom;
  text-shadow: $shadow-10;

  &:focus,
  &:hover {
    outline: 0;
  }

  &:hover {
    background: $color-success-30;
    color: $color-white;
  }

  &:active,
  &:focus {
    background: $color-success-70;
    box-shadow: inset 0 1px 3px rgba($color-black, 0.2);
    top: 2px;
  }

  &--block {
    display: block;
    width: 100%;
  }

  &:not(:only-child) {
    margin-right: $spacing;
  }

  &:disabled {
    cursor: inherit;
    background-color: #ddd;
    box-shadow: 0 2px #ddd;
  }

  /*------------------------------------*\
    #Styles
  \*------------------------------------*/

  &--light {
    background: $color-white;
    color: $color-primary-50;
    text-shadow: 0 0 0;
    box-shadow: 0 2px $color-neutral-20;

    &:hover {
      background: $color-white;
      color: $color-primary-50;
    }

    &:active {
      background: $color-primary-10;
      box-shadow: none;
      color: $color-primary-50;
    }
  }

  &--transparent {
    background: linear-gradient(180deg, rgba($color-white, 0.9), rgba($color-white, 1));
    box-shadow: 0 2px rgba($color-white, 0.5);
    color: $color-primary-50;
    text-shadow: 0 0 0;

    &:hover {
      background: $color-white;
      color: $color-primary-50;
    }

    &:active {
      background: $color-primary-10;
      box-shadow: none;
      color: $color-primary-50;
    }
  }

  &--neutral {
    background: $color-neutral-20;
    color: $color-neutral-50;
    text-shadow: 0 0 0;
    box-shadow: 0 2px $color-neutral-40;

    &:hover {
      background: $color-neutral-10;
      color: $color-neutral-70;
    }

    &:active {
      background: $color-primary-10;
      box-shadow: none;
      color: $color-primary-50;
    }
  }

  /*------------------------------------*\
    #Sizes
  \*------------------------------------*/

  &--s {
    padding: $spacing * 1.5 $spacing * 2;
    font-size: $font-size-s;
    min-width: auto;
  }

  &--l {
    @media (min-width: $breakpoint-30) {
      padding: $spacing * 2;
    }
  }
}
