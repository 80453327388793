/*------------------------------------*\
  #OBJECTS-LABELS
\*------------------------------------*/

.c-label {
  background: $color-white;
  border: 2px solid $color-primary-50;
  border-radius: $border-radius-10;
  color: $color-white;
  display: inline-block;
  font-size: $font-size-xxs;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  min-width: $spacing-xxs;
  padding: 1px 3px;
  text-shadow: 0 1px 0 rgba($color-white, 0.2);
  text-transform: uppercase;
  vertical-align: baseline;

  &--rounded {
    border-radius: 100%;
  }

  &--no-spacing {
    margin: 0 !important;
  }

  &--spacing-right {
    margin-right: $spacing-xs;
  }


  &--alert {
    border-color: $color-alert-50;
    color: $color-alert-50;

    .c-icon {
      fill: $color-white;
    }

    &.c-label--solid {
      background: $color-alert-50;
      color: $color-white;
    }
  }

}

.c-label--neutral {

  border-color: $color-neutral-20;
  color: $color-neutral-40;

  .c-icon {
    fill: $color-neutral-70;
  }
}

.c-label--success {
  border-color: $color-success-50;
  color:  $color-success-50;

  .c-icon {
    fill: $color-white;
  }
}

.c-label--warning {
  border-color: $color-warning-50;
  color: $color-warning-50;

  &.c-label--solid {
    background: $color-warning-50;
    color: $color-white;
  }

  .c-icon {
    fill: $color-white;
  }
}

.c-labels {
  list-style: none;
  display: inline-block;
  width: 100%;

  &__item {
    display: inline-block;
  }
}

.c-label + .c-heading {
  margin-top: $spacing-xxs;
}