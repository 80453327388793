/*------------------------------------*\
  #OBJECTS-ICONS
\*------------------------------------*/

$icon-size-s: 16px;
$icon-size-m: 24px;
$icon-size-l: 32px;

.c-icon {
  display: inline-flex;
  position: relative;
  top: -1px;
  vertical-align: middle;

  &--size-s {
    height: $icon-size-s;
    width: $icon-size-s;
  }

  &--size-m {
    height: $icon-size-m;
    width: $icon-size-m;
  }

  &--size-l {
    height: $icon-size-l;
    width: $icon-size-l;
  }

  &--dacom {
    pointer-events: none;
    opacity: 0.2;
    fill: $color-neutral-70;
    display: none;

    @media (min-width: $breakpoint-20) {
      display: block;
    }
  }

  &--twitter {

  }

  &--twitter,
  &--facebook,
  &--linkedin {
    border-radius: $border-radius-20;
    fill: $color-white;
    padding: $spacing-xxs;
    margin-right: $spacing-xs + 4px;
  }

  &--twitter {
    background: $color-twitter;
  }

  &--facebook {
    border-radius: $border-radius-20;
    fill: $color-white;
    background: $color-facebook;
    padding: $spacing-xxs;
  }

  &--linkedin {
    background: $color-linkedin;
  }

  &--inline {
   position: relative;
   top: -1px;
   margin: 0 $spacing-xxs 0 0;
  }

  &--spacing {
    margin-bottom: $spacing;
  }

  &--no-spacing-right {
    margin-right: 0;
  }
}


