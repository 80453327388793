/*------------------------------------*\
  #OBJECTS-PAGINATION
\*------------------------------------*/

/*ul*/.c-pagination {
  font-size: $font-size-s;
  list-style: none;
  margin-bottom: $spacing;

  &__item {
    display: inline-block;
    margin: 0 0 0.25rem 0.25rem;
    font-family: $font-family-heading;
    font-weight: bold;
    line-height: 1;
    text-align: center;
    vertical-align: top;

    &--gap {
      background: none;
      padding: 0.75rem 0.5rem;
      min-height: 40px;
    }

    a {
      border: 1px solid $color-neutral-30;
      background: $color-white;
      border-radius: $border-radius-10;
      color: $color-neutral-70;
      line-height: 1.5em;
      display: block;
      padding: 0.55rem 0.75rem;
      min-width: 40px;
      min-height: 40px;
      text-align: center;
      position: relative;

      &:hover {
        border-color: $color-neutral-30;
        color: $color-neutral-70;
      }

      &:active,
      &:focus {
        box-shadow: inset 0 1px 3px rgba($color-black, 0.2);
      }
    }
  }

  .c-icon {
    position: absolute;
    top: 11px;
    left: 12px;
  }
}

/*li*/.c-pagination__item--selected a {
  background: $color-neutral-90;
  border-color: $color-neutral-90;
  color: $color-white;

  &:hover {
    background: $color-black;
    border: 0;
    color: $color-white;
  }

  &:active,
  &:focus {
    box-shadow: inset 0 1px 3px rgba($color-black, 0.2);
  }
}