
.l-grid {
  width: 100%;

  @media (min-width: $breakpoint-20) {
    display: grid;
    grid-column-gap: $spacing-s;
    grid-row-gap: $spacing-m;

    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      align-items: flex-start;
      display: flex;
    }
  }

  @media (min-width: $breakpoint-30) {
    grid-row-gap: $spacing-l;
    grid-column-gap: $spacing-l;
  }

  &--4 {

    @media (min-width: $breakpoint-30) {
      grid-template-columns: repeat(3, 1fr);

      @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        flex: 0;
        min-width: calc(99.9%/3);
      }
    }
  }

  &--6 {

    @media (min-width: $breakpoint-30) {
      grid-template-columns: repeat(2, 1fr);

      > div {
        @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
          flex: 0;
          min-width: calc(99.9%/2);
        }
      }
    }
  }

  &--sidebar {

    @media (min-width: $breakpoint-30) {
      grid-template-columns: 1fr 200px;
    }
  }
}
