$block: 'c-map';
.#{$block} {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  height: calc(100vh - 64px);

  @media (min-width: $breakpoint-30) {
    margin-left: 320px;
    margin-right: 360px;
  }
}

.c-veld-detail {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  max-width: 360px;
  top: 64px;
  overflow-y: auto;
  
  @media (min-width: $breakpoint-30) {
    display: block;
    min-width: 360px;
  }
}