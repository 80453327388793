$block: 'sg-colors';
.#{$block} {
  $size: $spacing*16;

  list-style: none;
  display: flex;
  margin-bottom: $spacing-m;

  &__item {
    align-items: flex-end;
    border: $spacing solid $color-white;
    box-shadow: $shadow-20;
    display: flex;
    height: $size;
    width: $size + $spacing-m;

    &:not(:only-child) {
      margin-right: $spacing-s;
    }

    &--10 {
      background: $color-neutral-10;
    }

    &--20 {
      background: $color-neutral-20;
    }

    &--30 {
      background: $color-neutral-30;

      .#{$block}--primary & {
        background: $color-primary-30;
      }

      .#{$block}--secondary & {
        background: $color-secondary-30;
      }

      .#{$block}--success & {
        background: $color-success-30;
      }
    }

    &--40 {
      background: $color-neutral-40;
    }

    &--50 {
      background: $color-neutral-50;

      .#{$block}--primary & {
        background: $color-primary-50;
      }

      .#{$block}--secondary & {
        background: $color-secondary-50;
      }

      .#{$block}--success & {
        background: $color-success-50;
      }
    }

    &--70 {
      background: $color-neutral-50;

      .#{$block}--primary & {
        background: $color-primary-70;
      }

      .#{$block}--secondary & {
        background: $color-secondary-70;
      }

      .#{$block}--success & {
        background: $color-success-70;
      }
    }
  }

  &__var {
    background: $color-white;
    padding: $spacing-xs $spacing-xs 0 $spacing-xs;
    width: 100%;
    color: $color-neutral-50;
    font-weight: bold;
    font-size: $font-size-xxs;
  }
}