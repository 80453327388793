$block: 'c-status';
.#{$block} {
  display: inline-block;
  text-transform: uppercase;
  text-align: center;
  border: 2px solid;
  font-size: $font-size-m;
  padding: 1px 3px;
  min-width: 8em;
  margin-bottom: 2px;

  &--gemeld {
    border-color: $color-neutral-40;
    color: $color-neutral-40;
  }

  &--goedgekeurd {
    border-color: $color-success-50;
    color: $color-success-50;
  }

  &--afgekeurd {
    border-color: $color-alert-50;
    color: $color-alert-50;
  }

  &--ingediend-rvo {
    border-color: $color-primary-50;
    color: $color-primary-50;
  }
}
