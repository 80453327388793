/*------------------------------------*\
  #BASE-PAGE
\*------------------------------------*/

*,
*:after,
*:before {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
}

html {
  background: $color-neutral-10;
  color: $color-neutral-70;
  font: 400 $font-size-s $font-family-body;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  line-height: 1.5;
  letter-spacing: 0.1px;
}

body {
  margin: 0;
  display: flex;
  min-height: 100%;
  flex-direction: column;
  overflow-x: hidden;
  margin-top: 64px;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: block;
  }
}


