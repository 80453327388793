/*------------------------------------*\
  #BASE-TABLES
\*------------------------------------*/

table,
.c-table {
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: $spacing-m;
  width: 100%;
  position: relative;
  border-bottom: 1px solid $color-neutral-20;

  thead {
    position: relative;
    z-index: $z-index-10;
  }

  thead th {
    background: $color-neutral-10;
    color: $color-neutral-70;
    font-weight: 600;
    padding: $spacing $spacing*2;
    text-align: left;
    position: relative;

     @media (--breakpoint-30) {
      padding: $spacing*3;
    }

    a {
      color: $color-neutral-70;

      &:after {
        display: none;
      }

      &:hover {
        color: var(--color-neutral-90);
      }
    }
  }
  
  tbody {
    overflow: auto;
    height: 100px;

    tr {
      background: $color-white;
      transition: $timing-10 $bezier;
    }

    td {
      border-top: 1px solid $color-neutral-20;
      padding: $spacing*2;
      vertical-align: middle;

      @media (--breakpoint-30) {
        padding: $spacing*3;
      }
    }
  }

  &__td--neutral {
    background: $color-neutral-15 !important;
  }

  &__td--border-left {
    border-left: 1px solid $color-neutral-20;
  }
}

#wrap {
  overflow: auto;
  max-height: 80vh;
}