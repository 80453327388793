$block: 'c-sidebar';
.#{$block} {
  $search-size: 48px;

  background: $color-neutral-10;
  padding-top: $search-size;
  padding-bottom: $spacing-xxl;
  display: none;
  position: fixed;
  top: 64px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $z-index-10;

  @media (min-width: $breakpoint-30) {
    width: 320px;
    right: auto;
    border-right: $default-border;
    display: block;
  }

  &--always-visible {
    display: block;
  }

  &__content {
    overflow-y: auto;
    height: 100%;
    position: relative;
    box-shadow: 0 1px 0 $color-neutral-20;
  }

  &__title {
    background: $color-neutral-10;
    padding: $spacing-s $spacing-s $spacing-xxs $spacing-s;
  }

  .c-heading--spacing-xs {
    padding-right: $spacing-m;
  }

  &__search {
    margin-bottom: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    .c-form-field__input {
      margin: 0;
    }

    input {
      margin-bottom: 0;
      border: none;
      height: $search-size;
      border-radius: 0;
      border-bottom: 1px solid $color-neutral-20;
      background-image: url('../images/search.svg');
      background-repeat: no-repeat;
      background-position: 4px center;
      background-size: 24px;
      padding-left: $spacing-l;

      &:focus {
        border: none;
        border-bottom: 1px solid $color-neutral-20;
      }
    }
  }

  &__logo {
    position: absolute;
    left: $spacing-s;
    bottom: $spacing-xs;
    top: auto;
    z-index: $z-index-30;
  }
}