$block: 'c-account';
.#{$block} {
  list-style: none;
  box-shadow: $shadow-20;
  margin: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  text-align: left;
  top: 64px;
  transform: translate3d(0, -$spacing-xxs, 0);
  transition: $timing-10;
  z-index: $z-index-30;

  @media (min-width: $breakpoint-10) {

  }

  &:after {

  }

  .c-navigation__user--open & {
    opacity: 1;
    pointer-events: auto;
    transform: translate3d(0,0,0);
    visibility: visible;
  }

  &__item {
    display: block;
    border-bottom: $default-border;
  }

  &__link {
    background: $color-white;
    display: block;
    font-weight: bold;
    padding: $spacing-s $spacing-m;

    &:hover,
    &:focus{
      background: $color-neutral-10;
    }
    
    &.active {
      background: $color-neutral-20;
    }
  }
}