$block: 'c-form-checkbox';
.#{$block} {
  margin-bottom: $spacing-xs;

  &:last-child {
    margin-bottom: $spacing-l;
  }

  &:only-child {
    margin-bottom: 0;
  }

  .c-table &,
  .data-table & {
    display: inline-block;
    background: none;
    margin: 0;
  }

  &__label {
    min-height: $spacing-s;
    padding: $spacing $spacing $spacing $spacing-m;
    display: block;
    cursor: pointer;
    position: relative;

    .c-table &,
    .data-table & {
      display: inline-block;
      min-height: auto;
      padding: 0;
      border: 0;
    }

    &:active {
      border-color: $color-primary-50;
    }
  }

  &__input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  &__indicator {
    border-radius: $border-radius-10;
    position: absolute;
    top: $spacing + 2;
    left: 0;
    width: $spacing-s;
    height: $spacing-s;
    background: $color-white;
    border: 1px solid $color-neutral-30;

    .c-table &,
    .data-table & {
      top: 2px;
      position: relative;
    }

    &:after {
      top: 0px;
      left: 4px;
      width: 7px;
      height: 10px;
      transform: rotate(45deg);
      border: solid #fff;
      border-width: 0 2px 2px 0;
      position: absolute;
      display: none;
      content: '';
    }
  }
}

/* Hover and focus states */
.c-form-checkbox__label:hover input ~ .c-form-checkbox__indicator,
.c-form-checkbox input:focus ~ .c-form-checkbox__indicator {
  border-color: $color-neutral-30;
}


/* Checked state */
.c-form-checkbox input:checked ~ .c-form-checkbox__indicator {
  background: $color-primary-50;
  border-color: $color-primary-50;
}

/* Hover state whilst checked */
.c-form-checkbox:hover input:not([disabled]):checked ~ .c-form-checkbox__indicator,
.c-form-checkbox input:checked:focus ~ .c-form-checkbox__indicator {
  border-color: $color-primary-90;
}

/* Disabled state */
.c-form-checkbox input:disabled ~ .c-form-checkbox__indicator {
  pointer-events: none;
  opacity: .6;
  background: #e6e6e6;
}

/* Show check mark */
.c-form-checkbox input:checked ~ .c-form-checkbox__indicator:after {
  display: block;
}

/* Disabled tick colour */
.c-form-checkbox input:disabled ~ .c-form-checkbox__indicator:after {
  border-color: #7b7b7b;
}