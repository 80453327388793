/*------------------------------------*\
  #SETTINGS-COLORS
\*------------------------------------*/

/*
 * Most of the variables are based on some kind of percentages to maintain consistency.
 * So $variable-10 is small or light and $variable-100 is big or dark.
 */


/*
 * Primary colors
 */
$color-primary-10: #F3F8FC;
$color-primary-30: #2EC0FF;
$color-primary-40: #29ACE3;
$color-primary-50: #0080D1; // Default
$color-primary-70: #1B7196;
$color-primary-90: #00619E;


/*
 * Secondary colors
 */

$color-secondary-30: lighten(#462EE6, 10%);
$color-secondary-50: #462EE6; // Default
$color-secondary-70: #2F1F99;


/*
 * Success and positive colors
 */
$color-success-30: #82C924;
$color-success-50: #71B020; // Default
$color-success-70: #517D16;


/*
 * Alert en negative colors
 */
$color-alert-50: #E34029; // Default
$color-alert-70: darken(#E34029, 10%);

$color-warning-50: #F28100;
$color-warning-70: #F28100;

$color-cyan-50: #28C7BF;
$color-cyan-70: #1F9999;

/*
 * Neutral colors
 */

$color-neutral-10: #F6F7F8;
$color-neutral-15: darken(#F6F7F8, 2%);
$color-neutral-20: #E9E9EB;
$color-neutral-30: #CACACC;
$color-neutral-40: #808D9D;
$color-neutral-50: #494B4C;
$color-neutral-70: #404040;
$color-neutral-90: #0D384A;

/*
 * Black and white
 */
$color-black: #222;
$color-white: #fff;


/*
 * Gradients
 */
$gradient-orange: linear-gradient(90deg, $color-warning-70, $color-warning-50);


/*
 * Border
 */

$default-border: 1px solid $color-neutral-20;

/*
 * Social
 */
$color-twitter: #1da1f2;
$color-google: #dd4b39;
$color-facebook: #3a5795;
$color-linkedin: #0077B5;


/*
 * Set your font
 */
$font-family-body: 'Roboto', Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;
$font-family-heading: 'Roboto', Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;


/*
 * Set your font size
 */
$font-size-base:  100%;
$font-size-xxs:   10px;
$font-size-xs:    12px;
$font-size-s:     14px;
$font-size-m:     16px;
$font-size-l:     20px;
$font-size-xl:    24px;
$font-size-xxl:   28px;

/*
 * Input size for radio & chechboxes
 */
$input-size: 24px;


/*
 * Default spacing for margin-bottom and other paddings
 */

$columns: 12;

$spacing: 8px;

$spacing-xxs: calc($spacing/2);
$spacing-xs:  $spacing;
$spacing-s:   $spacing*2;
$spacing-m:   $spacing*3; //default
$spacing-l:   $spacing*4;
$spacing-xl:  $spacing*5;
$spacing-xxl: $spacing*6;
$spacing-xxxl: $spacing*8;

/*
 * Maximum width of the website
 */

$max-width: $spacing*180;

/*
 * Adjust the border-radius
 */
$border-radius-10: 2px;
$border-radius-20: 4px;


/*
 * Media queries
 */
$breakpoint-5:  500px;
$breakpoint-10: 600px;
$breakpoint-20: 750px;
$breakpoint-30: 1000px;
$breakpoint-40: 1250px;
$breakpoint-50: 1400px;


/*
 * Z-index plan
 */
$z-index-10: 10;
$z-index-20: 20;
$z-index-30: 30;
$z-index-40: 40;


/*
 * Shadows
 */
$shadow-10: 0 1px 2px rgba($color-black, 0.1);
$shadow-20: 0 2px 10px rgba($color-black, 0.1);
$shadow-40: 0 5px 20px rgba($color-black, 0.3); //hover



/*
 * Animation beziers
 */
$bezier: ease-in-out;

$timing-10: 0.1s;
$timing-20: 0.2s;
$timing-30: 0.6s;

