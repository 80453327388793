/*------------------------------------*\
  #TOOLS-GRID
\*------------------------------------*/
/*
 * The grid-container mixin is necessary for the floats
 */
@mixin grid-container() {

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after { clear: both; }
}



