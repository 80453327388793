$block: 'c-heading';
.#{$block}{
  color: $color-black;
  position: relative;
  font-weight: 600;
  font-size: $font-size-m;


  /*------------------------------------*\
  #Sizes & weights
  \*------------------------------------*/

  &--font-size-xs {
    font-size: $font-size-xs;
  }

  &--font-size-s {
    font-size: $font-size-s;
  }

  &--font-size-l {
    font-size: $font-size-xl;

    @media (min-width: $breakpoint-20) {
      font-size: $font-size-xxl;
    }
  }



  /*------------------------------------*\
  #Margins
  \*------------------------------------*/

  &--spacing-none {
    margin-bottom: 0;
  }

  &--spacing-xs {

    @media (min-width: $breakpoint-30) {
      margin-bottom: calc($spacing/4);
    }
  }

  &--spacing-s {

    @media (min-width: $breakpoint-30) {
      margin-bottom: calc($spacing/2);
    }
  }

  &--spacing-m {

    @media (min-width: $breakpoint-30) {
      margin-bottom: $spacing;
    }
  }

  &--spacing-l {
    margin-bottom: $spacing*2;

    @media (min-width: $breakpoint-30) {
      margin-bottom: $spacing*2;
    }
  }

  &--spacing-top {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: $spacing-xxs;
    padding: $spacing-m $spacing-s 0 $spacing-s;

    small {
    }
  }


  /*------------------------------------*\
  #Colors
  \*------------------------------------*/

  &--light {
    color: $color-white;
  }

  &--soft {
    color: $color-neutral-40;

    .#{$block}__link {
      color: inherit;
    }
  }

  &__optional {
    color: $color-neutral-40;
    font-size: $font-size-s;
    font-weight: normal;
    text-transform: uppercase;
  }
}