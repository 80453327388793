.l-row {

  &--neutral {
    background: $color-neutral-10;
    position: relative;
  }

  &--light {
    background: $color-white;
    min-height: 95vh;
  }

  &--image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    min-height: 50vh;
    display: flex;
    align-items: center;
  }

  &--full-screen {
    min-height: calc(100vh - 64px);
  }

  &__container {
    @include grid-container;
    margin: 0 auto;
    max-width: $max-width;
    position: relative;
    width: 100%;
    padding: $spacing-s;

    @media (min-width: $breakpoint-10) {
      padding-top: $spacing-l;
    }
  }

  &__single {
    margin: auto;
    max-width: $spacing*80;

    &--small {
      max-width: $spacing*60;
    }

    &--left {
      margin: 0;
    }
  }
}

.is-loading,
.is-loading a {
  cursor: progress;
}