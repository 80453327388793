$block: 'c-form-radio';
.#{$block} {
  margin-bottom: $spacing-xs;
  position: relative;
  
  &__label {
    min-height: $spacing-m;
    padding: $spacing $spacing $spacing $spacing-xxl;
    background: $color-neutral-10;
    display: block;
    cursor: pointer;
    position: relative;
    border: 1px solid $color-neutral-10;

    &:active {
      border-color: $color-primary-50;
    }
  }

  &__input {
    position: absolute;
    top: $spacing;
    left: $spacing-s;

    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: $color-white;
    border-radius: 100%;
    border: 1px solid $color-neutral-40;
    cursor:pointer;
    display: inline-block;
    height: $input-size;
    margin: 0;
    outline: none;
    padding: 1px;
    vertical-align: top;
    width: $input-size;

    &:hover {
      border-color: $color-neutral-40;
    }

    &::-ms-expand {
      display: none;
    }
  }
}

input[type="radio"]:checked:before {
    background: $color-primary-50;
    position: absolute;
    top: calc($spacing/2);
    left: calc($spacing/2);
    content: '';
    right: calc($spacing/2);
    bottom: calc($spacing/2);
    z-index: 999;
    border-radius: 100%;
}