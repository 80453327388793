$block: 'c-alert';
.#{$block} {
  border: 1px solid $color-neutral-20;
  background: $color-white;
  color: $color-neutral-70;
  font-size: $font-size-s;
  padding: $spacing*2 $spacing*2 $spacing+2 $spacing*2;
  position: relative;
  vertical-align: baseline;

  &--neutral {
    background: $color-neutral-10;
  }

  &--success {
    border-color: $color-success-50;
    background: $color-success-50;
    color: $color-white;
  }

  &--warning {
    border-color: $color-warning-50;
    background: $color-warning-50;
    color: $color-white;
  }

  &--error {
    border-color: $color-alert-50;
    background: $color-alert-50;
    color: $color-white;
  }

  &--blue {
    color: $color-white;
    background: $color-primary-50;
    border-color: $color-primary-50;
    border-radius: 10px;
  }

  .c-heading:first-child {
    margin-top: $spacing-xs;
  }

  &__close {
    position: absolute !important;
    right: 0;
    z-index: $z-index-30;
    top: $spacing-xs;
    display: none;
    &--visible {
      display: inline-block;
    }
  }
}

.c-alert-wrapper {

  &--map {
    position: fixed;
    top: $spacing-xxxl;
    left: 0;
    right: 0;
    z-index: $z-index-20;

    @media (min-width: $breakpoint-30) {
      margin-left: 320px;
      margin-right: 360px;
    }
  }

  &--toast {
    position: fixed;
    bottom: $spacing-xs;
    right: $spacing-xs;
    z-index: $z-index-20;
  }

  .#{$block} {
    margin: $spacing-m auto $spacing-xxs auto;
    max-width: $spacing*60;
  }
  
  &--hidden {
    display: none;
  }

  &--visible {
    display: block;
  }
}