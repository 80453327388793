$block: 'c-navigation-secondary';
.#{$block} {
  background: $color-white;
  border-top: $default-border;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: $z-index-20;

  @media (min-width: $breakpoint-30) {
    border-top: none;
    top: 64px;
    border-bottom: $default-border;
    bottom: auto;
    display: none;
  }

  &__list {
    list-style: none;
    margin: 0;
    display: flex;
    text-align: center;
    z-index: $z-index-30;
    justify-content: center;

    @media (min-width: $breakpoint-30) {
      justify-content: flex-start;
    }
  }

  &__item {
    flex: 1;

    &:not(:first-child) {
      border-left: $default-border;
    }

    @media (min-width: $breakpoint-30) {
      flex: 0 auto;
    }

    &--only-mobile {
      display: block;

      @media (min-width: $breakpoint-30) {
        display: none;
      }
    }

    &--only-desktop {
      display: none;

      @media (min-width: $breakpoint-30) {
        display: block;
      }
    }
  }

  &__link {
    display: block;
    color: $color-neutral-40;
    padding: $spacing-s $spacing-m;
    font-size: $font-size-s;
    position: relative;
    transition: $timing-10 $bezier;
    font-weight: 600;
    line-height: 1;

    @media (min-width: $breakpoint-30) {
      padding: $spacing-m;
    }

    &:hover,
    .#{$block}__item--selected & {
      color: $color-primary-50;
    }

    .#{$block}__item--selected & {
      font-weight: bold;
    }
  }

  &__icon {
    transition: $timing-10 $bezier;
    fill: $color-neutral-40;

    .#{$block}__item:hover &,
    .#{$block}__item--selected & {
      fill: $color-primary-50;
    }
  }
}

.c-navigation-secondary + .l-row {
  margin-top: 64px;
}

.c-navigation-secondary + .c-alert-wrapper {
  margin-top: 64px;
}
