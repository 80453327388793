$block: 'c-list';
.#{$block} {
  list-style: none;
  font-size: $font-size-m;
  margin: 0 0 $spacing-m 0;

  &__item {
    padding: $spacing $spacing $spacing $spacing-m;
    position: relative;

    &:before {
      background: $color-primary-50;
      border-radius: 100%;
      content: " ";
      height: 6px;
      line-height: 1;
      left: 0;
      position: absolute;
      top: $spacing*2+1;
      width: 6px;
    }
  }
}

ol.c-list {

  .#{$block}__item {
    background: none;
    counter-increment: list__counter;

    &:before {
      background: none;
      border-radius: 0;
      top: 12px;
      content: counter(list__counter)".";
    }
  }
}
