$block: 'c-loading';
.#{$block} {
  $size: 40px;

  position: relative;
  height: 100%;
  width: 100%;

  &.c-loading-inline {
    padding: $size;
    height: $size;
  }

  &::before {
    background: rgba($color-white, 0.6);
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }

  &::after {
    animation: loading $timing-30 infinite linear;
    border: 4px solid $color-neutral-20;
    border-radius: 100%;
    border-right-color: $color-primary-50;
    content: '';
    height: $size;
    left: 50%;
    margin: calc($size/-2) 0 0 calc($size/-2);
    opacity: 1;
    position: absolute;
    top: 50%;
    transform-origin: center;
    transition: all $timing-30 ease 0s;
    width: $size;
    z-index: 2;
  }
}

#loading {
  bottom: 0;
  cursor: wait;
  display: none;
  height: 100%;
  left: 0;
  padding-bottom: $spacing-xxxl*2;
  position: fixed;
  right: 0;
  top: $spacing-xxxl;
  visibility: visible;
  width: 100%;
  z-index: 999;
}

.loading-container {
  bottom: 0;
  cursor: wait;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: $spacing-xxxl;
  visibility: visible;
  width: 100%;
  z-index: 999;
}

@keyframes loading {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}