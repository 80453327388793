$block: 'c-form-field';
.#{$block},
.gfield {
  flex: 1;
  margin-bottom: $spacing-s;

  &--error {
    border-left: 3px solid $color-alert-50;
    padding-left: $spacing-s;

    .#{$block}__label {
      color: $color-alert-50;

      &:hover { color: $color-alert-50; }
    }

    .c-form-input,
    .c-form-input:focus {
      border: 1px solid $color-alert-50;
      box-shadow: 0 0 0;
      color: $color-alert-50;
    }
  }

  &--success {

    .#{$block}__label {
      color: $color-success-50;

      &:hover { color: $color-success-70; }
    }

    .#{$block}__input {
      position: relative;
      color: $color-success-50;
    }

    .#{$block}__input,
    .#{$block}__input:focus {
      border: 1px solid $color-success-50;
      box-shadow: 0 0 0;
      background: rgba($color-success-50, 0.1);
    }
  }


  &__label,
  &_label {
    clear: left;
    cursor: pointer;
    display: inline-block;
    font-weight: 600;
    font-size: $font-size-s;
    color: $color-neutral-70;
    padding: 1px 0 0 0;

    small {
      color: $color-neutral-40;
      margin-left: $spacing;
      text-transform: uppercase;
      font-size: $font-size-xs;
    }

    &:hover {
      color: $color-neutral-90;
    }
  }

  &__description,
  &_description {
    display: block;
    margin-bottom: $spacing-xxs;
    font-size: $font-size-s;
    color: $color-neutral-50;
  }

  &__description + &__input {
    margin-top: $spacing-xs - 1px;
  }

  &__error-message {
    color: $color-alert-50;
    font-weight: bold;
    margin-top: -$spacing-xxs;
    margin-bottom: $spacing-xs;
  }

  &__input {
    margin-top: $spacing-xxs;
    margin-bottom: $spacing-s;

    .#{$block}--size-xs & {
      max-width: $spacing*14;
    }

    .#{$block}--size-s & {
      max-width: $spacing*20;
    }

    .#{$block}--size-m & {
      max-width: $spacing*40;
    }

    .#{$block}--size-l & {
      max-width: $spacing*60;
    }

    .#{$block}--size-xl & {
      max-width: $spacing*80;
    }
  }
}

