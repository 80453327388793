$block: 'c-navigation-mobile';
.#{$block} {

  &__toggle {
    color: $color-white;
    display: block;
    font-size: $font-size-xxs;
    font-weight: bold;
    padding: $spacing-l + 2 $spacing-s $spacing-s - 2 $spacing-s;
    position: relative;
    text-transform: uppercase;

    &:hover {
      color: $color-white;
    }

    @media (min-width: $breakpoint-40) {
      display: none;
    }

    &:before,
    &:after {
      height: 2px;
      content: '';
      position: absolute;
      background: $color-white;
      width: $spacing-l;
      display: block;
    }

    &:before {
      top: $spacing-s + 4;
    }

    &:after {
      top: $spacing-m + 2;
    }
  }

  &__list {
    background: $color-white;
    bottom: 0;
    left: 0;
    list-style: none;
    margin: 0;
    position: absolute;
    right: 0;
    top: 64px;
    position: fixed;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    pointer-events: none;
    visibility: hidden;

    .c-navigation-mobile--is-open & {
      pointer-events: auto;
      visibility: visible;
    }
  }

  &__item {
    display: block;
    width: 100%;
    opacity: 0;
    transform: translate3d(0, $spacing-xxs, 0);
    transition: $timing-10 ease;

    .c-navigation-mobile--is-open & {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }

    &:first-child {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }

    @for $i from 1 to 10 {
      &:nth-child(#{$i}) { transition-delay: $i * 0.02s; }
    }
  }

  &__link {
    background: $color-white;
    border-bottom: $default-border;
    display: block;
    font-weight: bold;
    font-size: $font-size-s;
    padding: $spacing-s;
    transition: $timing-10;

    &:hover,
    &:focus{
      background: $color-neutral-10;
    }
    
    &.active {
      background: $color-neutral-20;
    }
  }
}

body.c-navigation-mobile--is-open {
  position: fixed;
}