$block: 'c-form-radio';
.#{$block} {
  margin-bottom: $spacing-xs;
}


.c-form-radio-group {
  border-radius: $border-radius-20;
  display: inline-block;
  margin: 0;
  list-style: none;

  &__item {
    position: relative;
    float: left;
    text-align: center;

    @media (min-width: $breakpoint-20) {
      min-width: $spacing*10;
    }

    &:first-child {
      border-left: 1px solid $color-neutral-30;
      border-radius: $border-radius-10 0 0 $border-radius-10;

      .#{$block}__label {
        border-radius: $border-radius-10 0 0 $border-radius-10;
      }
    }

    &:last-child {
      border-radius: 0 $border-radius-10 $border-radius-10 0;

      .#{$block}__label {
        border-radius: 0 $border-radius-10 $border-radius-10 0;
      }
    }
  }

  input {
    position: absolute;
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    clip: rect(0 0 0 0);
  }


  input:checked ~ label {
      background: $color-primary-10;
      color: $color-primary-50;
      margin: 0;
      font-weight: bold;
      border-color: $color-primary-50;
      //outline: 1px solid $color-primary-50;
  }

  .#{$block}__label {
    background: none;
    border: 1px solid $color-neutral-30;
    color: $color-neutral-70;
    border-left-color: transparent;
    padding: $spacing-xs $spacing-s;
  }
}

