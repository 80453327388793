$block: 'c-header';
.#{$block} {
  position: relative;
  overflow: hidden;

  &:before,
  &:after {
    background-size: contain;
    content: '';
    display: block;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    z-index: $z-index-10;
  }

  &__content {
    text-shadow: $shadow-10;
    padding-bottom: $spacing-xxl;

    @media (min-width: $breakpoint-10) {
      padding: $spacing-s $spacing-l $spacing-xxxl 0;
    }

    @media (min-width: $breakpoint-20) {
      padding: $spacing-xl $spacing-l $spacing-xxxl*2 $spacing-l;
    }

    @media (min-width: $breakpoint-40) and (min-height: $breakpoint-20) {
      padding: $spacing-xl $spacing-l $spacing-xxxl*3 $spacing-l;
    }

    @media (min-width: $breakpoint-50) {
      padding-bottom: $spacing-xxxl*4;
    }

    .#{$block}--minified & {
      height: auto;
      padding: $spacing-s 0 $spacing-s 0;

      @media (min-width: $breakpoint-10) {
        padding: $spacing-s 0 $spacing-xxxl 0;
      }

      @media (min-width: $breakpoint-30) {
        padding: $spacing-s $spacing-l $spacing-xxl*2 $spacing-l;
      }
    }
  }
  
  &__video {
    margin: auto;
    height: 100%;
    width: 100%;
  }

  &__video-iframe {
    bottom: -40%;
    height: 230%;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    margin: auto;
    top: -45%;
    width: 230%;
    z-index: -1;

    @media (min-width: $breakpoint-20) {
      height: 130vw;
      width: 130vw;
    }
  }
}
