/*------------------------------------*\
  #UTILITIES-GLOBAL
\*------------------------------------*/

.u-hidden { display: none !important; }

.u-invisible { visibility: hidden !important; }

.u-text-left { text-align: left; }

.u-text-right { text-align: right; }

.u-text-center { 
  @media (min-width: $breakpoint-10) {
    text-align: center; 
  }
}

.u-float-left { float: left; }

.u-float-right { float: right; }

.u-no-spacing { margin: 0; }

.u-visible-mobile {
  display: block;

  @media (min-width: $breakpoint-10) {
    display: none;
  }
}

.u-visble-tablet-desktop {
  display: none;

  @media (min-width: $breakpoint-10) {
    display: block;
  }

  &--inline {
    @media (min-width: $breakpoint-10) {
      display: inline-block;
    }
  }
}

.u-no-pointer-events {
  pointer-events: none;
}

.is-loading,
.is-loading a {
  cursor: progress;
}

.u-columns {
  column-gap: $spacing-l;

  @media (min-width: $breakpoint-10) {
    -ms-column-count: 2;
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
  }

  @media (min-width: $breakpoint-20) {
    column-gap: $spacing-xxxl;
  }
}

