.l-flex {
  display: flex;
  
  &--row {
    list-style: none;
    flex-flow: row wrap;
    width: 100%;
  }
}

.l-flex--col {
  flex-flow: column wrap;
}

.l-flex--center {
  align-items: center;
}

.l-flex--content-center {
  align-items: center;
}

.l-flex--align-end {
  align-items: flex-end;
}

.l-flex--space-between {
  justify-content: space-between;
}

.l-flex--flex-start {
  align-items: flex-start;
}


@for $i from 1 through 12 {
  .l-flex--#{$i} {
    flex: 1 100%;

    @media (min-width: $breakpoint-20) {
      flex-basis: calc(99.9% / #{$columns} * #{$i});
      max-width: calc(99.9% / #{$columns} * #{$i});
    }

    &--not-reponsive {
      flex-basis: calc(99.9% / #{$columns} * #{$i});
    }
  }
}